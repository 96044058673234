import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import Link from "src/utils/Link"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import { usePages } from "src/context/pages"
import Logo from "src/images/structured-data-logo.png"
import GetStarted from "src/components/Banners/GetStarted"
import LinkArrowReverse from "src/components/Icons/LinkArrowReverse"
import CaseStudiesCards from "src/components/CaseStudiesCards"
import Img from "gatsby-image"

const CaseStudySubPage: FC<Props> = ({
  html,
  metaTitle,
  metaDescription,
  title,
  quote,
  featuredImage,
  seeAll,
  logo,
  caseStudiesTitle,
  caseStudies,
  caseStudy,
}) => {
  const { currentPath, getPage } = usePages()

  return (
    <Layout
      title={metaTitle}
      description={metaDescription}
      openGraph={{
        title: metaTitle,
        description: metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
      headerClassname={`white-nav`}
    >
      <div className={styles.root}>
        <div className={`content-wrapper ${styles.header}`}>
          <Link className={styles.backTo} to={getPage("case-studies").url}>
            <LinkArrowReverse className={styles.arrow} />
          </Link>
          <Img
            fluid={logo.childImageSharp.fluid}
            placeholderStyle={{ visibility: "hidden" }}
            className={styles.logo}
            style={{
              height: caseStudy === "aurea" ? "36px" : "28px",
              width: "100%",
            }}
            imgStyle={{ objectFit: "contain" }}
          />
          <h2 className={styles.title}>{title}</h2>
          <blockquote>{quote}</blockquote>
        </div>
        <div className={`content-wrapper ${styles.featuredImage}`}>
          <Img
            fluid={featuredImage.childImageSharp.fluid}
            placeholderStyle={{ visibility: "hidden" }}
            className={styles.logo}
          />
        </div>
        <div
          className={styles.markdownStyles}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
      <div className={`content-wrapper ${styles.bottomSection}`}>
        <div className={styles.caseStudiesHeader}>
          <h2 className={styles.caseStudiesTitle}>{caseStudiesTitle}</h2>
          <Link to={getPage("case-studies").url} className={styles.link}>
            {seeAll}
          </Link>
        </div>
        <CaseStudiesCards variant="small" caseStudies={caseStudies} />
        <GetStarted />
      </div>
    </Layout>
  )
}

export default CaseStudySubPage
