import React, { FC } from "react"
import * as styles from "./styles.module.scss"
import { Props, CaseStudy } from "./types"
import { graphql, useStaticQuery } from "gatsby"
import { useComponentTexts } from "src/hooks/useComponentTexts"
import Link from "src/utils/Link"
import Img from "gatsby-image"
import ArrowLink from "src/components/ArrowLink"
import { usePages } from "src/context/pages"

const CaseStudiesCards: FC<Props> = ({
  className,
  mainCard = "top",
  variant,
  caseStudies = ["dribbble", "bench", "webuild", "aurea", "persist-digital"],
  hasMain = true,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allFile (filter: { relativePath: {regex: "/components/CaseStudiesCards\/.+\\.yml/"}}) {
        nodes {
          childYaml {
            cases {
              id
              title
              shortTitle
              quote
              img {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              logo {
                img {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                width
                height
              }
            }
            buttonText
          }
          relativePath
        }
      }
    }
  `)
  const texts = useComponentTexts(data)
  const { getPage } = usePages()
  const caseStudiesFilter = caseStudies.map(id =>
    texts.cases.find((caseStudy: CaseStudy) => caseStudy.id === id),
  )
  return (
    <div
      className={`${styles.postList} ${className} ${
        variant === "small" ? styles.smallGrid : ""
      }`}
    >
      {caseStudiesFilter.map((caseStudy: CaseStudy, index) => (
        <Link
          className={`${styles.post} ${
            index === 0 && hasMain ? styles.main : ""
          } ${mainCard == "middle" ? styles.middle : ""} ${
            variant === "small" ? styles.postSmall : ""
          }`}
          to={getPage(`case-studies/${caseStudy.id}`).url}
          key={index}
        >
          <div className={styles.imgContent}>
            <Img fluid={caseStudy.img.childImageSharp.fluid} />
          </div>
          <div className={styles.infoContent}>
            <Img
              fluid={caseStudy.logo.img.childImageSharp.fluid}
              placeholderStyle={{ visibility: "hidden" }}
              className={`${styles.logo}`}
              style={{
                height: caseStudy.logo.height,
                width: caseStudy.logo.width,
              }}
            />
            {variant === "small" ? (
              <p className={styles.title}>{caseStudy.shortTitle}</p>
            ) : (
              <>
                <p className={styles.title}>{caseStudy.title}</p>
                <p
                  className={styles.quote}
                  dangerouslySetInnerHTML={{ __html: caseStudy.quote }}
                />
                <ArrowLink
                  text={texts.buttonText}
                  to={getPage(`case-studies/${caseStudy.id}`).url}
                  className={`button button-second ${styles.button}`}
                  as="span"
                />
              </>
            )}
          </div>
        </Link>
      ))}
    </div>
  )
}

export default CaseStudiesCards
